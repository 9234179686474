import React from "react"
import { Link } from "gatsby"
import { Nav, Navbar } from "react-bootstrap"

import Logo from "../images/triple-l-hotel-logo_with-text.png"
import { LocaleContext } from "./layout"
import LanguageSelector from "./languageSelector"

const Header = () => {
  const [navExpanded, setNavExpanded] = React.useState(false)
  const {
    pageContext: { lang, defaultPath },
    ...props
  } = React.useContext(LocaleContext)

  // if the current path is same as defaultPath mean we are on the
  // default language page. set at data/config.js
  // if not add the langauge to pathPrefix and use on the nav
  //
  // props.path is default gatsby props
  // defaultPath is pageContext generate from gatsby-node.js
  const pathPrefix = props.path === defaultPath ? "" : `/${lang}`

  const t = {
    en:{
      home: "Home",
      theHotel: "The Hotel",
      accommodations: "Accommodations",
      restaurantBar: "Restaurant & Bar",
      location: "Location",
      contact: "Contact",
    },
    th:{
      home: "หน้าแรก",
      theHotel: "พันธกิจ",
      accommodations: "ห้องพัก",
      restaurantBar: "ร้านอาหาร",
      location: "สถานที่",
      contact: "ติดต่อ",
    },
    cn:{
      home: "首頁",
      theHotel: "酒店",
      accommodations: "房間",
      restaurantBar: "餐廳&酒吧",
      location: "地點",
      contact: "聯繫",
    },
  }

  return (
    <header>
      <Navbar
        expand="xl"
        expanded={navExpanded}
        onToggle={() => setNavExpanded(!navExpanded)}
      >
        <Navbar.Brand as={Link} to={`${pathPrefix}/`}>
          <img src={Logo} alt="Triple L Hotel Phuket Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="m-auto align-items-center">
            <Nav.Link
              as={Link}
              to={`${pathPrefix}/`}
              onClick={() => setNavExpanded(false)}
              activeClassName="active"
            >
              {t[lang].home}
            </Nav.Link>
            <Nav.Link
              as={Link}
              to={`${pathPrefix}/about-hotel/`}
              onClick={() => setNavExpanded(false)}
              activeClassName="active"
            >
              {t[lang].theHotel}
            </Nav.Link>
            {/* <NavDropdown title="Accommodationss" id="basic-nav-dropdown">
              <NavDropdown.Item
                as={Link}
                to={`${pathPrefix}/rooms/deluxe/`}
                activeClassName="active"
              >
                Deluxe
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to={`${pathPrefix}/rooms/grand-deluxe/`}
                activeClassName="active"
              >
                Grand Deluxe
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to={`${pathPrefix}/rooms/one-bedroom-suite/`}
                activeClassName="active"
              >
                One Bedroom Suite
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to={`${pathPrefix}/rooms/two-bedroom-suite/`}
                activeClassName="active"
              >
                Two Bedroom Suite
              </NavDropdown.Item>
            </NavDropdown> */}
            <Nav.Link
              as={Link}
              to={`${pathPrefix}/rooms/deluxe/`}
              onClick={() => setNavExpanded(false)}
              activeClassName="active"
            >
              {t[lang].accommodations}
            </Nav.Link>
            <Nav.Link
              as={Link}
              to={`${pathPrefix}/restaurant-bar/`}
              onClick={() => setNavExpanded(false)}
              activeClassName="active"
            >
              {t[lang].restaurantBar}
            </Nav.Link>
            <Nav.Link
              as={Link}
              to={`${pathPrefix}/location/`}
              onClick={() => setNavExpanded(false)}
              activeClassName="active"
            >
              {t[lang].location}
            </Nav.Link>
            <Nav.Link
              as={Link}
              to={`${pathPrefix}/contact/`}
              onClick={() => setNavExpanded(false)}
              activeClassName="active"
            >
              {t[lang].contact}
            </Nav.Link>
          </Nav>
          <Nav className="text-info align-items-xl-center">
            <LanguageSelector
              currentLanguage={lang}
              path={defaultPath}
              setNavExpanded={setNavExpanded}
            />
          {/* <a href="https://triplelhotel.bookengine.com/" target="_blank" className="btn btn-primary px-5 py-3">
            Book Now
          </a> */}
        </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
}

export default Header
