/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import Header from "./header"
import Head from "./head"
import Topbar from "./topbar"
import Footer from "../components/footer"
import "../scss/main.scss"

const LocaleContext = React.createContext()

const Layout = props => {
  return (
    <LocaleContext.Provider value={{ ...props }}>
      <Head />
      <Topbar />
      <Header />
      <main>{props.children}</main>
      <Footer />
    </LocaleContext.Provider>
  )
}

export { LocaleContext }
export default Layout
