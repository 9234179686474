import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"

import theme from "./theme"
import SocialIcons from "../components/social-icons"
import logo from "../images/triple-l-hotel-logo_with-text.png"
import { LocaleContext } from "./layout"

const Footer = styled.footer`
  padding: 5rem 0 1rem 0;
  background: ${theme.colors.light};
  min-height: 300px;
  box-shadow: 0 -1px 1.5rem rgba(0, 0, 0, 0.1);
`

const Logo = styled.div`
  width: 250px;
  height: 80px;
  margin: auto;

  img {
    width: 100%;
    height: auto;
  }

  ${theme.breakpoint.md} {
    width: 320px;
    height: 80px;
    margin: 2rem 1rem 0 auto;
  }
`
export default function FooterSection() {
  const {
    pageContext: { lang },
  } = React.useContext(LocaleContext)

  const t = {
    en: {
      address: "Mailing Address",
      phone: "Phone",
      fax: "Fax",
    },
    th: {
      address: "ที่อยู่",
      phone: "โทร",
      fax: "แฟ็กซ์",
    },
    cn: {
      address: "郵寄地址",
      phone: "電話",
      fax: "傳真",
    },
  }

  return (
    <Footer>
      <Container>
        <Row>
          <Col>
            <Logo>
              <img src={logo} alt="Triple L Hotel Logo" />
            </Logo>
          </Col>
          <Col className="text-center text-md-left pl-md-4">
            <h4 className="h5 font-weight-bold mt-3 mt-md-0">
              {t[lang].address}
            </h4>
            <address>
              72/1, 50 Pee Road
              <br />
              Tambon Patong, Amphur Kathu,
              <br />
              Phuket, Thailand 83150
            </address>
            <p>
              Email:{" "}
              <a href="mailto:info@triplelhotelphuket.com">
                info@triplelhotelphuket.com
              </a>
              <br />
              {t[lang].phone}: +66 076 345 105 - 8 <br />
              {t[lang].fax}: +66 076 345 109
            </p>
            <SocialIcons />
          </Col>
        </Row>

        <Row className="border-top mt-5 pt-3">
          <Col>
            <p>&copy; COPYRIGHT 2019 TRIPLE L HOTEL</p>
          </Col>
          <Col>
            <p className="text-right">PRIVACY &amp; COOKIES POLICY</p>
          </Col>
        </Row>
      </Container>
    </Footer>
  )
}
