import React from "react"
import styled from "styled-components"

import theme from "../components/theme"
import FacebookFSvg from "../images/icons/icon-facebook-f.svg"
import IgSvg from "../images/icons/icon-instagram.svg"
import LineSvg from "../images/icons/icon-line.svg"

const Icons = styled.div`
  display: flex;
  justify-content: center;

  ${theme.breakpoint.md}{
    justify-content: flex-start;
  }
`

const Icon = styled.span`
  width: 32px;
  height: 32px;
  padding: 4px;
  display: inline-block;
  text-align:center;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }

  svg {
    height: 100%;
    width: auto;
    fill: ${theme.colors.dark};
  }
`

export default function SocialIcons(props) {
  return (
    <Icons>
      <Icon>
        <FacebookFSvg />
      </Icon>
      <Icon>
        <IgSvg />
      </Icon>
      <Icon>
        <LineSvg />
      </Icon>
    </Icons>
  )
}
