// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-hotel-js": () => import("./../../../src/pages/about-hotel.js" /* webpackChunkName: "component---src-pages-about-hotel-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-location-js": () => import("./../../../src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-restaurant-bar-js": () => import("./../../../src/pages/restaurant-bar.js" /* webpackChunkName: "component---src-pages-restaurant-bar-js" */),
  "component---src-pages-rooms-deluxe-js": () => import("./../../../src/pages/rooms/deluxe.js" /* webpackChunkName: "component---src-pages-rooms-deluxe-js" */)
}

