const white = "#fff"
const black = "#000"
const gray100 = "#f8f9fa"
const gray200 = "#e9ecef"
const gray300 = "#dee2e6"
const gray400 = "#ced4da"
const gray500 = "#adb5bd"
const gray600 = "#868e96"
const gray700 = "#495057"
const gray800 = "#373a3c"
const gray900 = "#212529"
const blue = "#2780E3"
const indigo = "#6610f2"
const purple = "#613d7c"
const pink = "#e83e8c"
const red = "#FF0039"
const orange = "#f0ad4e"
const yellow = "#FF7518"
const green = "#3FB618"
const teal = "#20c997"
const cyan = "#9954BB"

const primary = blue
const secondary = gray800
const success = green
const info = orange
const warning = yellow
const danger = red
const light = gray100
const dark = gray800
const gray = gray400

const size = {
  xs: 375,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}

export default {
  colors: {
    white,
    black,
    gray100,
    gray200,
    gray300,
    gray400,
    gray500,
    gray600,
    gray700,
    gray800,
    gray900,
    blue,
    indigo,
    purple,
    pink,
    red,
    orange,
    yellow,
    green,
    teal,
    cyan,
    primary,
    secondary,
    success,
    info,
    warning,
    danger,
    light,
    dark,
    gray,
  },
  breakpoint: {
    sm: `@media (min-width: ${size.sm}px)`,
    md: `@media (min-width: ${size.md}px)`,
    lg: `@media (min-width: ${size.lg}px)`,
    xl: `@media (min-width: ${size.xl}px)`,
    xsDown: `@media (max-width: ${size.sm - 0.1}px)`,
    smDown: `@media (max-width: ${size.md - 0.1}px)`,
    mdDown: `@media (max-width: ${size.lg - 0.1}px)`,
    lgDown: `@media (max-width: ${size.xl - 0.1}px)`,

    xsOnly: `@media (max-width: ${size.sm - 0.1}px)`,
    smOnly: `@media (min-width: ${size.sm}px) and (max-width: ${size.md - 0.1}px)`,
    mdOnly: `@media (min-width: ${size.md}px) and (max-width: ${size.lg - 0.1}px)`,
    lgOnly: `@media (min-width: ${size.lg}px) and (max-width: ${size.xl - 0.1}px)`,
    xlOnly: `@media (min-width: ${size.xl}px)`,
  },
  font:{
    main:`Rubik, "Helvetica Neue", Helvetica, Arial, sans-serif`,
    header: `"Playfair Display", serif;`
  },

  shadow:{
    main: `0 0.5rem 1rem rgba(0, 0, 0, 0.15)`,
    lg: `0 1rem 3rem rgba(0, 0, 0, 0.2)`
  }
}
