module.exports = {
  en: {
    default: true,
    path: ``,
    languageName: `English`,
    position: 0,
    locale: `en-US`,
    dateFormat: `DD/MM/YYYY`,
    siteLanguage: `en`,
    ogLanguage: `en_US`,
    defaultTitle: `Triple L Phuket`,
    defaultDescription: `Triple L Hotel is centrally located on the best panorama of Patong Bay, Phuket. The hotel has a pool and bar on the rooftop. The beach is just 10 minutes walking from the hotel.`,
  },
  th: {
    path: `/th`,
    languageName: `Thai`,
    locale: `th-TH`,
    position: 1,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `th`,
    ogLanguage: `th_TH`,
    defaultTitle: `Triple L Phuket`,
    defaultDescription: `Triple L Hotel ตั้งอยู่บนจุดที่เห็นหาดป่าตองได้สวยที่สุดในภูเก็ต เก็บบรรยากาศดีๆจากสระว่ายน้ำที่ชั้นบนสุด ใช้เวลาเดินไปที่หาดป่าตองเพียง 10 นาที`,
  },
  cn: {
    path: `/cn`,
    languageName: `Chinese`,
    locale: `th-TH`,
    position: 2,
  }
}
