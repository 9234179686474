import React from "react"
import Helmet from "react-helmet"

const Head = () => (
  <Helmet>
    <link
      href="https://fonts.googleapis.com/css?family=Playfair+Display|Rubik&display=swap"
      rel="stylesheet"
    />
  </Helmet>
)
export default Head