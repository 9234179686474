import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

import theme from "./theme"
import config from "../data/config"
import FlagsSvg from "../images/icons/flags.svg"

const Wrapper = styled.div`
  padding: 0.5rem;
  cursor: pointer;
  position: relative;
`

const Current = styled.span`
  text-transform: uppercase;
  color: ${theme.colors.secondary};
  display: flex;
  align-items: center;
  justify-content:center;

  ${theme.breakpoint.xl} {
    border-right: 0;
  }

  &:after {
    display: none;

    ${theme.breakpoint.xl} {
      content: "";
      margin-left: 8px;
      display: inline-block;
      vertical-align: 3px;
      border-top: 4px solid ${theme.colors.secondary};
      border-right: 4px solid transparent;
      border-bottom: 0;
      border-left: 4px solid transparent;
    }
  }
`

const Dropdown = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: auto;

  ${theme.breakpoint.xl} {
    min-width: 180px;
    display: none;
    position: absolute;
    right: 0;
    margin-top: 0.5rem;
    z-index: 999;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: ${theme.shadow.main};
    border: 1px solid ${theme.colors.light};
    color: ${theme.colors.secondary};
  }

  h4 {
    display: block;
    width: 100%;
    font-family: ${theme.font.main};
    font-size: 0.75rem;
    color: ${theme.colors.secondary};
    padding-bottom: 0.5rem;
    border-bottom: 1px solid ${theme.colors.gray200};
    margin-bottom: 0.5rem;
    text-align: center;
    ${theme.breakpoint.xl} {
      display: block;
    }
  }

  ${Wrapper}:hover & {
    ${theme.breakpoint.xl} {
      display: flex;
    }
  }

  a {
    width: 100%;
    text-align: center;
    font-size: 0.75rem;
    padding: 0.25rem;
    margin-bottom: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  a:hover {
    background: ${theme.colors.gray200};
  }
`

const StyledFlag = styled.span`
  width: 24px;
  height: 16px;
  display: inline-block;
  padding: 0;
  margin-right: 0.5rem;
  overflow: hidden;

  & > svg {
    transform: ${({ position }) => `translateY(-${position}px)`};
    width: 100%;
    height: auto;
  }

  ${Dropdown} & {
    margin-right: 0.5rem;
  }
`

const LanguageSelector = ({ currentLanguage, path,setNavExpanded }) => {
  const flagHeight = 16

  return (
    <Wrapper>
      <Current>
        <small className="mr-2 d-xl-none">Current language</small>
        <StyledFlag position={config[currentLanguage].position * flagHeight}>
          <FlagsSvg />
        </StyledFlag>{" "}
        {currentLanguage}
      </Current>
      <Dropdown>
        <h4>Select Language</h4>
        {Object.keys(config).map(lang => {
          if (lang !== currentLanguage) {
            return (
              <Link to={`${config[lang].path}${path}`} onClick={() => setNavExpanded(false)} key={lang}>
                <StyledFlag position={config[lang].position * flagHeight}>
                  <FlagsSvg />
                </StyledFlag>
                {config[lang].languageName}
              </Link>
            )
          }
          return null
        })}
      </Dropdown>
    </Wrapper>
  )
}

export default LanguageSelector
