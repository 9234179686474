import React from "react"
import styled from "styled-components"

const Icon = styled.span`
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 0.5rem;

  svg {
    fill: var(--primary);
  }
`
const Info = styled.span`
  vertical-align: middle;
`

const IconInfo = props => {
  const { icon: SvgIcon, info, href, ...rest } = props
  return (
    <div>
      <Icon>
        <SvgIcon />
      </Icon>
      {info && <Info>{info}</Info>}
      {href && (
        <Info>
          <a href={href} title={href} {...rest}>
            {href}
          </a>
        </Info>
      )}
    </div>
  )
}

export default IconInfo
