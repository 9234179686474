import React from "react"
import styled from "styled-components"
import { Box, Flex } from "rebass"

import theme from "../components/theme"
import IconInfo from "../components/icon-info"

// import SvgBuilding from "../images/icons/building.svg"
import SvgPhone from "../images/icons/telephone.svg"
import SvgMail from "../images/icons/mail.svg"
import SvgFB from "../images/icons/facebook.svg"
import SvgIG from "../images/icons/instagram.svg"
import SvgLine from "../images/icons/line.svg"

const Warper = styled(Box)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 0.75rem;
  padding: 0.5rem 1rem;
  display: none;
  background-color: ${theme.colors.light};
  max-height: 50px;

  ${theme.breakpoint.md} {
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
  }

  ${theme.breakpoint.lg} {
    padding: 0.5rem 2rem;
  }
`
const Social = styled(Box)`
  display: inline-block;
  width: 24px;
  height: 24px;

  &:not(:last-child) {
    margin-right: 1rem;
  }

  svg {
    width: 100%;
    height: auto;
  }

  ${theme.breakpoint.md} {
    display: none;
  }
  ${theme.breakpoint.lg} {
    display: inline-block;
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
`
const Topbar = () => {
  return (
    <Warper justifyContent="space-between">
      <Box as="span">Triple L Hotel Patong beach, Phuket, Thailand</Box>

      <Flex justifyContent="flex-end">
        <Box mr={3}>
          <Social href="facebook.com">
            <SvgFB />
          </Social>
          <Social href="instagram.com">
            <SvgIG />
          </Social>
          <Social href="https://line.me/en/">
            <SvgLine />
          </Social>
        </Box>

        <Box mr={3}>
          <IconInfo icon={SvgPhone} info="+ 66 076 345 105 - 8" />
        </Box>

        <Box>
          <IconInfo
            icon={SvgMail}
            href="info@triplelhotelphuket.com"
            className="text-dark"
          />
        </Box>
      </Flex>
    </Warper>
  )
}

export default Topbar
